@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "FuturaPT-Book";
    src: local("FuturaPT-Book"),
        url("./assets/fonts/FuturaPT-Book.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Bold";
    src: local("FuturaPT-Bold"),
        url("./assets/fonts/FuturaPT-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Demi";
    src: local("FuturaPT-Demi"),
        url("./assets/fonts/FuturaPT-Demi.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Light";
    src: local("FuturaPT-Light"),
        url("./assets/fonts/FuturaPT-Light.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "TenorSans";
    src: local("TenorSans-Regular"),
        url("./assets/fonts/TenorSans-Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Molde-Expanded-Semibold";
    src: local("Molde-Expanded-Semibold"),
        url("./assets/fonts/Molde-Expanded-Semibold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Molde-Expanded-Regular";
    src: local("Molde-Expanded-Regular"),
        url("./assets/fonts/Molde-Expanded-Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Molde-Expanded-Medium";
    src: local("Molde-Expanded-Medium"),
        url("./assets/fonts/Molde-Expanded-Medium.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Molde-Expanded-Bold";
    src: local("Molde-Expanded-Bold"),
        url("./assets/fonts/Molde-Expanded-Bold.ttf") format("truetype");
    font-weight: bold;
}

.primalbrown {
    color: #c19652;

}

.h-half {
    height: 90vh;
}

.h-30vh {
    height: 30vh;
}

.h-40vh {
    height: 40vh;
}

.h-60vh {
    height: 60vh;
}

.h-80vh {
    height: 80vh;
}

.h-team {
    height: 80vh;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
}

.grid-item {
    position: relative;
}

.grid-item + .grid-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: white;
}


/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
    .h-team {
        height: 20vh; /* Adjust the height for mobile devices as needed */
    }
    
    .grid-container {
        grid-template-columns: 1fr;
    
    }
}

/* Media query for iPad devices */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .h-team {
        height: 60vh; /* Adjust the height for iPad devices as needed */
    }
}

input {
    border: 1px solid black;
}

select {
    border: 1px solid black;
}
